<template>
  <div class="container">
    <p style="text-align: center; padding: 10px;color: #999" v-if="applist.length==0 && softlist.length==0">暂无数据</p>
    <div class="item-app" v-for="(item,index) in applist" :key="index">
      <el-row class="row">
        <el-col :span="12">
          <p style="padding-top: 8px">
            <i class="el-icon-caret-right"></i><span>应用：</span>{{item.appname}}
          </p>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button icon="el-icon-delete" type="text" @click.prevent="deleteApp(item.id, index)" class="add-btn">删除应用</el-button>
          <el-button icon="el-icon-plus" type="text" @click.prevent="addSoft(item)" class="add-btn">为该应用添加软件</el-button>
        </el-col>
      </el-row>
      <el-table :data="item.soft" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="softname" label="应用软件">
        </el-table-column>
        <el-table-column prop="version" label="版本">
        </el-table-column>
        <el-table-column prop="type" label="类型">
        </el-table-column>
        <el-table-column prop="safe_port" label="占用端口">
        </el-table-column>
        <el-table-column label="安全信息">
          <template slot-scope="scope">
            <p v-if="scope.row.ref_waf">关联WAF：{{scope.row.ref_waf_name}}</p>
            <p v-if="scope.row.ref_dbcheck">关联WAF：{{scope.row.ref_dbcheck_name}}</p>
            <p v-if="scope.row.safe_data">数据加密：{{scope.row.safe_data}}</p>
            <p v-if="scope.row.safe_auth">多因素认证：{{scope.row.safe_auth}}</p>
            <p v-if="scope.row.safe_log">日志审计：{{scope.row.safe_log}}</p>
            <p v-if="scope.row.safe_backup">数据备份：{{scope.row.safe_backup}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="note" label="备注">
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <span class="row-opt" @click="editSoft(scope.row, item.appname)"><el-link type="primary" icon="el-icon-document">编辑</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span class="row-opt" @click="deleteSoft(scope.row.id)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
          </template>
        </el-table-column>
        <template slot="empty">
          <p>暂无软件</p>
        </template>
      </el-table>
    </div>

    <div class="item-app" v-show="softlist.length>0">
      <el-row class="row">
        <el-col :span="12">
          <p style="padding-top: 8px">
            <i class="el-icon-caret-right"></i>其他软件
          </p>
        </el-col>
        <el-col :span="12" style="text-align: right">
        </el-col>
      </el-row>
      <el-table :data="softlist" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="softname" label="软件">
        </el-table-column>
        <el-table-column prop="version" label="版本">
        </el-table-column>
        <el-table-column prop="type" label="类型">
        </el-table-column>
        <el-table-column prop="safe_port" label="占用端口">
        </el-table-column>
        <el-table-column label="安全信息">
          <template slot-scope="scope">
            <p v-if="scope.row.ref_waf">关联WAF：{{scope.row.ref_waf_name}}</p>
            <p v-if="scope.row.ref_dbcheck">关联WAF：{{scope.row.ref_dbcheck_name}}</p>
            <p v-if="scope.row.safe_data">数据加密：{{scope.row.safe_data}}</p>
            <p v-if="scope.row.safe_auth">多因素认证：{{scope.row.safe_auth}}</p>
            <p v-if="scope.row.safe_log">日志审计：{{scope.row.safe_log}}</p>
            <p v-if="scope.row.safe_backup">数据备份：{{scope.row.safe_backup}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="note" label="备注">
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <span class="row-opt" @click="editSoft(scope.row, '无')"><el-link type="primary" icon="el-icon-document">编辑</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span class="row-opt" @click="deleteSoft(scope.row.id)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <el-dialog :title="popTitle" :visible.sync="addSoftPop" :close-on-click-modal="false" width="65%">
      <hw-devicesoft-form :dev-id="devId" :id="softId" :app-id="appInfo.app_id" :app-name="appInfo.appname" :dev-name="devName" @closeSoftPop="closeSoftPop"></hw-devicesoft-form>
    </el-dialog>

  </div>
</template>

<script>
  import DevicesoftForm from "./DevicesoftForm";

  export default {
    name: "DeviceApp",
    components: {
      hwDevicesoftForm: DevicesoftForm,
    },
    props: {
      devId: {
        type: Number
      },
      devName: {
        type: String
      }
    },
    data() {
      return {
        applist: [],
        popTitle: '添加软件',
        addSoftPop: false,
        softId: null,
        appInfo: {},
        softlist: [],
      };
    },
    created() {
      this.getDevAppList();
      this.getSoftList();
    },
    methods: {
      //获取设备独立的软件列表
      getSoftList() {
        this.$get(this.$api.GetDevSoftList, {id: this.devId, app_id: 0}).then((res) => {
          if (res.code === 0) {
            this.softlist = res.data;
          } else {
            this.$message.error(res.message)
          }
        });
      },
      //获取设备关联的应用列表
      getDevAppList() {
        this.$get(this.$api.GetDevAppList, {id: this.devId}).then((res) => {
          if (res.code === 0) {
            this.applist = res.data;
          } else {
            this.$message.error(res.message)
          }
        });
      },

      deleteApp(id,index) {
        this.$confirm('此操作将删除该设备承载的应用吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteDevappMap, {id: id}).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.applist.splice(index, 1);
            } else {
              this.$message.error(res.message);
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      addSoft(item) {
        this.popTitle = '添加软件';
        this.addSoftPop = true;
        this.appInfo = item;
      },
      editSoft(row, appname) {
        this.popTitle = '编辑软件';
        this.addSoftPop = true;
        this.appInfo.app_id = row.app_id;
        this.appInfo.appname = appname;
        this.softId = row.id;
      },
      closeSoftPop() {
        this.addSoftPop = false;
        this.getDevAppList();
        this.getSoftList();
      },
      deleteSoft(id) {
        this.$confirm('此操作将删除该软件吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteDevSoft, {id: id}).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.getDevAppList();
            } else {
              this.$message.error(res.message);
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
  }
</script>

<style scoped>
  .row{
    margin-top: 10px;
  }
</style>
