<template>
    <el-popover placement="top-start" width="360" :title="title" v-model="showPopover" @show="loadPopoverData">
        <div class="popover-wrap">
            <el-form>
                <el-form-item>
                    <el-checkbox-group v-model="checked">
                        <el-checkbox :label="item.port" v-for="(item,index) in options" :key="index" style="width: 140px"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-input v-model="otherPort" size="small" class="edit-ipt" placeholder="输入其他端口,多个端口请用英文逗号隔开"></el-input>
            </el-form>
        </div>
        <div class="popover-btn">
            <el-button type="primary" size="small" @click="submitPopoverData">确定</el-button>
        </div>

        <span class="edit-btn" slot="reference">编辑</span>
    </el-popover>
</template>

<script>
    export default {
        name: "PopoverTag.vue",
        props: {
            title: {
                type: String
            },
            value: {
                type: String,
                default: ''
            },
            checklist: {
                type: Array
            },
            id: {
                type: Number
            },
            field: {
                type: String
            },
            mod: {
                type: String,
                default: 'device'
            }
        },
        data() {
            return {
                showPopover: false,
                options: this.checklist,
                checked: [],
                otherPort: '',
            }
        },

        created() {
            //console.log(this.value)
        },
        methods: {
            loadPopoverData() {
                console.log(this.value)
                if (this.value == '' || this.value == null) {
                    this.checked = [];
                } else {
                    this.checked = this.value.split(',');
                }

                this.$get(this.$api.GetSafePortList).then((res) => {
                    if (res.code === 0) {
                        this.options = res.data;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            submitPopoverData() {
                if (this.otherPort !== '') {
                    let other = this.otherPort.split(',');
                    this.checked = [...this.checked, ...other];
                }
                let str = this.checked.join(',');
                let arr = str.split(',');
                arr = [...new Set(arr)]; //去重

                let params = {
                    id: this.id,
                    field: this.field,
                    value: arr.join(',')
                }
                let url = this.$api.UpdateDevice;
                if (this.mod == 'soft') { //应用软件
                    url = this.$api.UpdateSoft
                }
                this.$post(url, params).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '编辑成功',
                            type: 'success'
                        });
                        this.$emit('emitSave', this.field, arr.join(','));
                        this.showPopover = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .popover-wrap{
        padding: 10px;
    }
    .popover-btn{
        text-align: center;
    }
    .edit-btn{
        cursor: pointer;
        color: #999;
        font-size: 13px;
    }
</style>