<template>
    <div class="container">
        <el-form :model="form" label-width="140px" @submit.prevent="addSubmitApp">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="当前设备">
                        <p>{{devName}}</p>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="应用名称">
                        <p>{{appName}}</p>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="软件类型" prop="soft_type">
                        <el-select v-model="form.soft_type"  placeholder="请选择" @change="selectType">
                            <el-option
                                    v-for="item in typelist"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="软件名称" prop="soft_id">
                        <el-select v-model="form.soft_id" filterable placeholder="请选择" @change="selectSoft">
                            <el-option
                                    v-for="item in softlist"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="当前版本" >
                        <el-input v-model="form.version" @input="inputChange" placeholder="选填" style="width: 100px"></el-input>
                        &nbsp; <el-checkbox v-model="useVersion" @change="selVersion">最新版本{{latestVersion}}</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="占用端口" prop="safe_port" >
                        <el-input v-model="form.safe_port" @input="inputChange" style="width: 217px" placeholder="选填，输入端口，如3306,443"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="数据加密" prop="safe_data" >
                <el-checkbox-group v-model="form.safe_data">
                    <el-checkbox :label="item" v-for="item in encryptList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="多因素认证" prop="safe_auth" >
                <el-checkbox-group v-model="form.safe_auth">
                    <el-checkbox :label="item" v-for="item in authList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="数据备份与恢复" prop="safe_backup" >
                <el-checkbox-group v-model="form.safe_backup">
                    <el-checkbox :label="item" v-for="item in backupList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日志审计" prop="safe_log" >
                <el-checkbox-group v-model="form.safe_log">
                    <el-checkbox :label="item" v-for="item in logList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="备注" prop="note" >
                <el-input v-model="form.note" placeholder="选填，如：主数据库"></el-input>
            </el-form-item>
            <div style="margin-top:30px; text-align: center">
                <el-button type="primary" native-type="submit" @click.prevent="addSubmitApp">提交</el-button>
                <el-button @click="closePop">取消</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "DevicesoftForm",
        props: {
            devId: {
                type: Number
            },
            id: {
                type: Number
            },
            devName: {
                type: String
            },
            appId: {
                type: Number,
                default: 0
            },
            appName: {
                type: String
            },
            mode: {
                type: String
            },
        },
        data() {
            return {
                typelist: [],
                softlist: [],
                wafList: [],
                datacheckList: [],
                encryptList: [],
                authList: [],
                backupList: [],
                logList: [],
                adminList: [],
                useVersion: false,
                latestVersion: '1.0',
                safe_data: [],
                safe_auth: [],
                form: {
                    soft_id: '',
                    //version: '',
                    safe_data: [],
                    safe_auth: [],
                    safe_backup: [],
                    safe_log: [],
                    safe_admin: [],
                },
            }
        },
        watch: {
            id(val, oldVal) {
                if (val != oldVal && val != null) {
                    this.getMapInfo();
                }
                if (val == null) {
                    this.form = {};
                }
            }
        },
        mounted() {
            this.getMapInfo();
        },
        created() {
            //this.getMapInfo();
            this.getTypeList();
            this.getOption('app'); //应用安全信息
        },
        methods: {
            inputChange(){
                this.$forceUpdate()
            },
            getMapInfo() {
                if (this.id != null) {
                    this.$get(this.$api.GetDevSoftInfo, {id: this.id}).then((res) => {
                        if (res.code === 0) {
                            this.form = res.data;
                            //this.getSoftList(res.data.soft_type);
                            this.form.safe_data = this.form.safe_data ? this.form.safe_data.split(',') : [];
                            this.form.safe_auth = this.form.safe_auth ? this.form.safe_auth.split(',') : [];
                            this.form.safe_backup = this.form.safe_backup ? this.form.safe_backup.split(',') : [];
                            this.form.safe_log = this.form.safe_log ? this.form.safe_log.split(',') : [];
                            this.form.safe_admin = this.form.safe_admin ? this.form.safe_admin.split(',') : [];
                        } else {
                            this.$message.error(res.message)
                        }
                    });
                } else {
                    this.form = {
                        soft_id: '',
                        safe_data: [],
                        safe_auth: [],
                        safe_backup: [],
                        safe_log: [],
                        safe_admin: [],
                    };
                    this.useVersion = false;
                }
            },
            getTypeList() {
                this.$get(this.$api.GetAppTypelist).then((res) => {
                    if (res.code === 0) {
                        this.typelist = res.data.list;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            selectType(val) {
                this.form.soft_id = '';
                this.getSoftList(val);
            },
            selectSoft(softId) {
                this.$get(this.$api.GetSoftInfo, {id: softId}).then((res) => {
                    if (res.code === 0) {
                        this.latestVersion = res.data.version;
                        if (res.data.port) {
                            this.form.safe_port = res.data.port;
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            getOption(mod) {
                this.$get(this.$api.GetSafeOptions, {field: '', mod: mod}).then((res) => {
                    if (res.code === 0) {
                        this.encryptList = res.data.safe_data;
                        this.authList = res.data.safe_auth;
                        this.backupList = res.data.safe_backup;
                        this.logList = res.data.safe_log;
                        this.adminList = res.data.safe_admin;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            selVersion(checked) {
                this.form.version = checked ? this.latestVersion : '';
            },
            //提交保存
            addSubmitApp() {
                if (this.form.soft_id === '') {
                    this.$message.warning('请选择承载的应用名称');
                    return false;
                }

                if (!this.devId) {
                    this.$message.warning('获取当前设备信息失败');
                    return false;
                }
                this.form.dev_id = this.devId;
                this.form.app_id = this.appId;

                let safeData = this.form.safe_data;
                if (safeData.length > 0) {
                    this.form.safe_data = this.form.safe_data.join(',');
                }
                let safeAuth = this.form.safe_auth;
                if (safeAuth.length > 0) {
                    this.form.safe_auth = this.form.safe_auth.join(',');
                }
                let safeBackup = this.form.safe_backup;
                if (safeBackup.length > 0) {
                    this.form.safe_backup = safeBackup.join(',');
                }
                let safeLog = this.form.safe_log;
                if (safeLog.length > 0) {
                    this.form.safe_log = safeLog.join(',');
                }
                let safeAdmin = this.form.safe_admin;
                if (safeAdmin.length > 0) {
                    this.form.safe_admin = safeAdmin.join(',');
                }

                let postUrl = this.$api.AddDevSoft;
                if (this.id != null) {
                    postUrl = this.$api.EditDevSoft;
                }
                this.$post(postUrl, this.form).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closePop();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            closePop() {
                this.form = {
                    soft_id: '',
                    safe_data: [],
                    safe_auth: [],
                    safe_backup: [],
                    safe_log: [],
                    safe_admin: [],
                };
                this.useVersion = false;
                //this.form = {};
                this.$emit('closeSoftPop');
            },
        }
    }
</script>

<style scoped>

</style>
