<template>
  <el-popover placement="top-start" width="280" :title="'请选择' + title" v-model="showPopover" @show="loadPopoverData">
    <el-select v-model="selId" size="small" placeholder="请选择" ref="editor" class="edit-ipt" @change="selOption">
      <el-option value="" label="----无----" style="color: #999"></el-option>
      <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
      </el-option>
    </el-select> &nbsp;
    <el-button type="primary" size="small" @click="submitPopoverData">确定</el-button>
    <span class="edit-btn" slot="reference">编辑</span>
  </el-popover>
</template>

<script>
  export default {
    name: "PopoverSelect",
    props: {
      title: {
        type: String
      },
      value: {
        //type: Number
      },
      id: {
        type: Number
      },
      field: {
        type: String
      },
      optionSelId: {
        type: Number
      },
      mod: {
        type: String,
        default: 'soft'
      },
      refSel: {
        type: String,
        default: 'soft'
      }
    },
    data() {
      return {
        showPopover: false,
        options: [],
        selLabel: '',
        selId: this.value
      }
    },
    created() {
      //
    },
    methods: {
      loadPopoverData() { //下拉选项
        let url = this.$api.GetSoftSimpleList;
        if (this.refSel == 'device') { //安全设备
          url = this.$api.GetSafeSimpleList
        }
        this.$get(url, {type: this.optionSelId}).then((res) => {
          if (res.code === 0) {
            this.options = res.data;
          } else {
            this.$message.error(res.message)
          }
        });
      },

      selOption(val) {
        let obj = {};
        obj = this.options.find(item => {
          return item.id === val;
        });
        this.selLabel = obj.name;
      },
      submitPopoverData() {
        let params = {
          id: this.id,
          field: this.field,
          value: this.selId
        }
        let url = this.$api.UpdateDevice;
        if (this.mod == 'soft') { //应用软件
          url = this.$api.UpdateSoft
        }
        this.$post(url, params).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$emit('emitSave', this.field, this.selLabel);
            this.showPopover = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    }
  }
</script>

<style scoped>
  .edit-ipt {
    width: 200px;
  }

  .edit-btn {
    cursor: pointer;
    color: #999;
    font-size: 13px;
  }
</style>
