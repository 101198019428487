<template>
    <div class="container">
        <el-form :model="form" label-width="200px" @submit.prevent="submit">
            <el-form-item label="当前设备">
                <p>{{devName}}</p>
            </el-form-item>
            <el-form-item label="选择关联应用">
                <el-select v-model="form.app_id">
                    <el-option
                            v-for="item in applist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="note" >
                <el-input v-model="form.note" placeholder="选填，如：备份机"></el-input>
            </el-form-item>
            <div style="margin-top:30px; text-align: center">
                <el-button type="primary" native-type="submit" @click.prevent="submit">提交</el-button>
                <el-button @click="closePop">取消</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "DeviceAppForm",
        props: {
            //设备id
            devId: {
                type: Number
            },
            //修改当前关联数据的id
            id: {
                type: Number
            },
            devName: {
                type: String
            }
        },
        data() {
            return {
                form: {},
                applist: [],
            }
        },
        watch: {
            id(val, oldVal) {
                if (val != oldVal) {
                    this.getMapInfo();
                }
            }
        },
        created() {
            this.getAppList();
        },
        methods: {
            //获取应用下拉选项
            getAppList() {
                this.$get(this.$api.GetAppList).then((res) => {
                    if (res.code === 0) {
                        this.applist = res.data.list;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            //获取应用与设备对应关系
            getMapInfo() {
                //
            },
            submit() {
                if (this.form.app_id == '') {
                    this.$message.warning('请选择应用');
                    return false;
                }
                this.form.dev_id = this.devId;
                this.form.tid = 1; //主机

                let postUrl = this.$api.AddDevappMap;
                if (this.id != null) {
                    postUrl = this.$api.EditDevappMap;
                    this.form.id = this.id;
                }

                this.$post(postUrl, this.form).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.closePop();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            closePop() {
                this.$emit('closeAppPop');
            }
        }
    }
</script>

<style scoped>

</style>