<template>
  <div class="container">
    <el-form :model="devForm" ref="deviceForm" label-width="200px" @submit.prevent="addSubmit">
      <el-form-item :label="label">
        <el-select v-model="devForm.ref_dev_id" filterable @change="changeSelDev">
          <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name + '(' + item.ip + ')'"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="连接方式">
        <el-select v-model="devForm.link_type" @change="selLinkType">
          <el-option
                  v-for="item in linkType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="showInterface">
        <el-form-item :label="label+'接口'" prop="ref_interface" >
          <el-select v-model="devForm.ref_interface">
            <el-option
                    v-for="item in refInterfaceList"
                    :key="item.name"
                    :label="item.name + '(' + item.ip + ')'"
                    :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本设备接口" prop="dev_interface" >
          <el-select v-model="devForm.dev_interface">
            <el-option
                    v-for="item in devInterfaceList"
                    :key="item.name"
                    :label="item.name + '(' + item.ip + ')'"
                    :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="备注" prop="note" >
        <el-input v-model="devForm.note" placeholder="选填，如：备份机"></el-input>
      </el-form-item>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="addSubmit">提交</el-button>
        <el-button @click="closePop">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "LinkForm",
    props: {
      //设备id
      devId: {
        type: Number
      },
      //关联设备label
      label: {
        type: String
      },
      //关联设备类型
      refCid: {
        type: Number
      },
      //关联设备ID
      refId: {
        type: Number
      }
    },
    data() {
      return {
        refName: '',
        loading: false,
        devForm: {},
        options: [],
        selectedLabel: '',
        linkType: [
          {name: '直连', value: 1},
          {name: '内网交换机', value: 2},
          {name: '互联网', value: 3},
          {name: '未知', value: 0},
        ],
        //关联设备接口列表
        refInterfaceList: [],
        //本设备接口列表
        devInterfaceList: [],
        //是否显示接口信息
        showInterface: false,
      }
    },
    watch: {
      refId(val) {
        if (val != undefined) {
          this.getMapInfo();
        }
      },
      refCid() {
        this.getDevlist();
        this.reset();
      },
    },
    mounted() {
      this.getDevlist();
      if (this.refId != undefined) {
        this.getMapInfo();
      }
    },
    created() {

    },
    methods: {
      getMapInfo() {
        let params = {
          dev_id: this.devId,
          ref_dev_id: this.refId
        };
        this.$get(this.$api.GetSafeDevMapInfo, params).then((res) => {
          if (res.code === 0) {
            this.devForm = res.data;
          } else {
            this.$message.error(res.message)
          }
        });
      },

      //获取安全设备类型下的设备列表
      getDevlist() {
        this.$get(this.$api.GetDeviceSimpleList, {type_id: this.refCid}).then((res) => {
          if (res.code === 0) {
            this.options = res.data;
          } else {
            this.$message.error(res.message)
          }
        });
      },
      //选中设备
      changeSelDev(val) {
        let obj = {};
        obj = this.options.find((item)=>{
          return item.id === val;//比如：选项2
        });
        //判断是否有选择，如果用户没有选择，但是删除了上一个选择内容，避免控制台报错
        if (obj != "" && obj != undefined) {
          this.selectedLabel = obj.name;//获取label的值
        }
      },

      //选中连接方式
      selLinkType(val) {
        if (val === 1) { //直连时，显示接口信息
          this.showInterface = true;
          //显示接口列表
          this.getInterfaceList(this.devId, 1);
          this.getInterfaceList(this.refId, 2);
        } else {
          this.showInterface = false;
        }
      },
      //获取设备接口列表
      getInterfaceList(devId, mod) {
        let params = {
          page: 1,
          limit: 50,
          dev_id: devId
        }
        this.$get(this.$api.GetInterfaceList, params).then((res) => {
          if (mod == 1) {
            this.devInterfaceList = res.data.list;
          } else {
            this.refInterfaceList = res.data.list;
          }
        });
      },
      addSubmit() {
        if (this.devForm.ref_dev_id == '') {
          this.$message.warning('请选择设备');
          return false;
        }
        if (this.devForm.link_type == '' && this.devForm.link_type != 0) {
          this.$message.warning('请选择链接方式');
          return false;
        }
        this.devForm.dev_id = this.devId;
        this.devForm.type = [2, this.refTid];
        let postUrl = this.$api.AddDevMap;
        if (this.devForm.id) {
          postUrl = this.$api.EditDevMap;
        }

        this.$post(postUrl, this.devForm).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: '操作成功！',
              type: 'success'
            });
            this.closePop(1);
          } else {
            this.$message.error(res.message);
          }
        });
      },
      reset() {
        this.devForm = {
          ref_dev_id: '',
          link_type: '',
          note: ''
        };
        //清空验证项
        this.$nextTick(() => {
          this.$refs['deviceForm'].clearValidate();
        });
        if (this.$refs['deviceForm']) {
          this.$refs['deviceForm'].resetFields();
        }
      },

      closePop(type) {
        this.reset();
        if (type == 1) {
          this.$emit('closeLinkPop', this.selectedLabel, this.refCid, this.devForm.ref_dev_id);
        } else {
          this.$emit('closeLinkPop');
        }
        //this.devForm = {};
      },
    }
  }
</script>

<style scoped>

</style>
