<template>
  <div class="container">
    <div class="page-title">
      <h3>主机详情
        <el-divider direction="vertical"></el-divider>
        <span @click="$router.go(-1)">返回</span>
      </h3>
    </div>
    <div class="wrap">
      <el-row class="row">
        <el-col :span="6"><span>主机名称：</span>{{devInfo.name}}</el-col>
        <el-col :span="6"><span>位置：</span>{{devInfo.roomname}} / {{devInfo.cabname}}</el-col>
        <el-col :span="6"><span>类型：</span>{{devInfo.btype}} / {{devInfo.stype}}</el-col>
        <el-col :span="6"><span>CPU：</span>{{devInfo.cpu}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>IP：</span>
          <i v-for="(item,i) in devInfo.interfaces" :key="i">
            {{item.ip}}({{item.name}}) <br/>
          </i>
        </el-col>
        <el-col :span="6"><span>MAC：</span>
          <i v-for="(item,j) in devInfo.interfaces" :key="j">
            {{item.mac}} <br/>
          </i>
        </el-col>
        <el-col :span="6"><span>内存：</span>{{devInfo.memory}}</el-col>
        <el-col :span="6"><span>磁盘空间：</span>{{devInfo.disk}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>品牌：</span>{{devInfo.brand}}</el-col>
        <el-col :span="6"><span>型号：</span>{{devInfo.model_no}}</el-col>
        <el-col :span="6"><span>序列号：</span>{{devInfo.sn_no}}</el-col>
        <el-col :span="6"><span>固定资产编号：</span>{{devInfo.asset_no}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>信创产品：</span><i v-if="devInfo.made_inchina==1">是</i></el-col>
        <el-col :span="6"><span>金额：</span>{{devInfo.price}}</el-col>
        <el-col :span="6"><span>开始使用时间：</span>{{devInfo.start_usetime}}</el-col>
        <el-col :span="6"><span>维保到期时间：</span>{{devInfo.main_time}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>操作系统：</span>{{devInfo.os}} {{devInfo.os_bit}} <i v-if="devInfo.os_inchina==1">(国产)</i></el-col>
        <el-col :span="6"><span>所属集群：</span>
          <i v-for="(it,i) in devInfo.groups" :key="i">
            {{it.name}} &nbsp;
          </i>
        </el-col>
        <el-col :span="6"><span>管理者：</span>{{devInfo.manager}}</el-col>
        <el-col :span="6"><span>备注：</span>{{devInfo.note}}</el-col>
      </el-row>

      <div class="tab-wrap">
        <el-button icon="el-icon-plus" type="text" @click.prevent="addApp" class="add-btn2" v-if="activeName=='app'">
          为该设备添加应用
        </el-button>
        <el-button icon="el-icon-plus" type="text" @click.prevent="addSoft" class="add-btn" v-if="activeName=='app'">
          单独添加软件
        </el-button>
        <el-button icon="el-icon-plus" type="text" @click.prevent="addRefDev" class="add-btn" v-if="activeName=='dev'">
          为该设备添加关联设备
        </el-button>
        <el-button icon="el-icon-plus" type="text" @click.prevent="addRefInterface" class="add-btn" v-if="activeName=='interfaces'">
          为该设备添加接口
        </el-button>
        <el-tabs v-model="activeName" type="card" class="tab" @tab-click="switchTab">
          <el-tab-pane label="关系图谱" name="map">
            <div ref="graphChart" style="width:100%;height: 600px"></div>
          </el-tab-pane>
          <el-tab-pane label="承载应用" name="app">
            <hw-device-app :dev-id="id" :dev-name="devInfo.name" ref="refapp" v-if="activeName=='app'"></hw-device-app>
          </el-tab-pane>
          <el-tab-pane label="主机安全信息" name="hostsafe">
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">账号安全</el-col>
              <el-col :span="20">
                {{devInfo.safe_account}}
                <hw-popover-checkbox :value="devInfo.safe_account" :id="id" field="safe_account" title="账号安全"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">系统安全</el-col>
              <el-col :span="20">
                {{devInfo.safe_sys}}
                <hw-popover-checkbox :value="devInfo.safe_sys" :id="id" field="safe_sys" title="系统安全"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">远程登录方式</el-col>
              <el-col :span="20">
                {{devInfo.safe_remote}}
                <hw-popover-checkbox :value="devInfo.safe_remote" :id="id" field="safe_remote" title="远程登录方式"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">主动防御</el-col>
              <el-col :span="20">
                {{devInfo.safe_defense}}
                <hw-popover-checkbox :value="devInfo.safe_defense" :id="id" field="safe_defense" title="主动防御"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">杀毒软件</el-col>
              <el-col :span="20">
                {{devInfo.host_vir_name}}
                <hw-popover-select :value="devInfo.host_vir" :id="id" :optionSelId="3" mod="device" refSel="soft"
                                   field="host_vir" title="杀毒软件" @emitSave="emitSaveName"></hw-popover-select>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">漏洞与补丁管理</el-col>
              <el-col :span="20">
                {{devInfo.host_patch}}
                <hw-popover-checkbox :value="devInfo.host_patch" :id="id" field="host_patch" title="漏洞与补丁"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">日志审计</el-col>
              <el-col :span="20">
                {{devInfo.host_log}}
                <hw-popover-checkbox :value="devInfo.host_log" :id="id" field="host_log" title="日志审计"
                                     @emitSave="emitSaveName"></hw-popover-checkbox>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="网络安全信息" name="netsafe">
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">所在防火墙</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.firewall">
                {{devInfo.ref_info.firewall.label}}
                <span class="edit-btn" @click="editLinkDev(7, '所在防火墙', devInfo.ref_info.firewall.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(7, '所在防火墙')">编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">所在交换机</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.exchanger">
                {{devInfo.ref_info.exchanger.label}}
                <span class="edit-btn" @click="editLinkDev(8, '所在交换机', devInfo.ref_info.exchanger.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(8, '所在交换机')">编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">所在路由器</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.router">
                {{devInfo.ref_info.router.label}}
                <span class="edit-btn" @click="editLinkDev(19, '所在路由器', devInfo.ref_info.router.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(19, '所在路由器')">编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">所在VPN</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.vpn">
                {{devInfo.ref_info.vpn.label}}
                <span class="edit-btn" @click="editLinkDev(11, '所在VPN', devInfo.ref_info.vpn.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(11, '所在VPN')">编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">关联堡垒机</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.fortress">
                {{devInfo.ref_info.fortress.label}}
                <span class="edit-btn" @click="editLinkDev(24, '关联堡垒机', devInfo.ref_info.fortress.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(24, '关联堡垒机')">编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">关联网闸设备</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.gate">
                {{devInfo.ref_info.gate.label}}
                <span class="edit-btn" @click="editLinkDev(28, '关联网闸设备', devInfo.ref_info.gate.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(28, '关联网闸设备')">编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">安全准入</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.access">
                {{devInfo.ref_info.access.label}}
                <span class="edit-btn" @click="editLinkDev(27, '安全准入', devInfo.ref_info.access.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(27, '安全准入')">编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">IPS入侵防御</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.ips">
                {{devInfo.ref_info.ips.label}}
                <span class="edit-btn"
                      @click="editLinkDev(30, 'IPS入侵防御', devInfo.ref_info.ips.ref_id)"
                >编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn"
                      @click="editLinkDev(30, 'IPS入侵防御')"
                >编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">日志审计</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.log">
                {{devInfo.ref_info.log.label}}
                <span class="edit-btn"
                      @click="editLinkDev(23, '日志审计', devInfo.ref_info.log.ref_id)"
                >编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn"
                      @click="editLinkDev(23, '日志审计')"
                >编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">防病毒</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.antivirus">
                {{devInfo.ref_info.antivirus.label}}
                <span class="edit-btn"
                      @click="editLinkDev(32, '防病毒', devInfo.ref_info.antivirus.ref_id)"
                >编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn"
                      @click="editLinkDev(32, '防病毒')"
                >编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">备份设备</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.backup">
                {{devInfo.ref_info.backup.label}}
                <span class="edit-btn"
                      @click="editLinkDev(29, '备份设备', devInfo.ref_info.backup.ref_id)"
                >编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn"
                      @click="editLinkDev(29, '备份设备')"
                >编辑</span>
              </el-col>
              <el-col :span="4" class="label-txt">安全检测</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.inspection">
                {{devInfo.ref_info.inspection.label}}
                <span class="edit-btn"
                      @click="editLinkDev(25, '安全检测', devInfo.ref_info.inspection.ref_id)"
                >编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn"
                      @click="editLinkDev(25, '安全检测')"
                >编辑</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">上网行为分析</el-col>
              <el-col :span="8" v-if="devInfo.ref_info.internet">
                {{devInfo.ref_info.internet.label}}
                <span class="edit-btn" @click="editLinkDev(31, '上网行为分析', devInfo.ref_info.internet.ref_id)">编辑</span>
              </el-col>
              <el-col :span="8" v-else>
                <span class="edit-btn" @click="editLinkDev(31, '上网行为分析')">编辑</span>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="rows">
              <el-col :span="4" class="label-txt">开放端口</el-col>
              <el-col :span="8">
                {{devInfo.safe_ports}}
                <hw-popover-tag :value="devInfo.safe_ports" :id="id" field="safe_ports" title="端口白名单"
                                @emitSave="emitSaveName"></hw-popover-tag>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="端口信息" name="ports">
            <el-row :gutter="20" class="rows">
              <el-col :span="6" class="label-txt">
                <strong>应用占用端口</strong><br/>
                <span>应用软件实际占用的端口</span>
              </el-col>
              <el-col :span="18">
                {{ports.soft_ports}}
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="6" class="label-txt">
                <strong>主机开放端口</strong><br/>
                <span>主机开放的其他机器可访问的端口</span>
              </el-col>
              <el-col :span="18">
                {{ports.dev_ports}}
              </el-col>
            </el-row>
            <el-row :gutter="20" class="rows">
              <el-col :span="6" class="label-txt">
                <strong>防火墙准入端口</strong><br/>
                <span>关联硬件防火墙准许的通信端口</span>
              </el-col>
              <el-col :span="18">
                {{ports.safe_ports}}
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="关联设备" name="dev">
            <hw-ref-devicelist :dev-id="id" :cid="devInfo.type_id" :type="devInfo.type" ref="refdev"
                               v-if="activeName=='dev'"></hw-ref-devicelist>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog :title="popTitle" :visible.sync="addAppPop" :close-on-click-modal="false">
      <hw-deviceapp-form :dev-id="id" :dev-name="devInfo.name + '(' + devInfo.ip + ')'" :id="refId"
                         @closeAppPop="closeAppPop"></hw-deviceapp-form>
    </el-dialog>

    <el-dialog title="关联设备" :visible.sync="showLinkPop" :close-on-click-modal="false" @close="resetForm">
      <hw-link-form ref="refForm" :dev-id="id" :ref-id="refInfo.refId" :ref-cid="refInfo.refCid" :label="refInfo.label" @closeLinkPop="closeLinkPop"></hw-link-form>
    </el-dialog>

    <el-dialog title="添加软件" :visible.sync="addSoftPop" :close-on-click-modal="false" width="65%">
      <hw-devicesoft-form :dev-id="id" :id="softId" :app-id="0" :dev-name="devInfo.name" app-name="无"
                          @closeSoftPop="closeSoftPop"></hw-devicesoft-form>
    </el-dialog>
  </div>
</template>

<script>
  import PopoverSelect from '@/components/pages/PopoverSelect.vue';
  import PopoverCheckbox from '@/components/pages/PopoverCheckbox.vue';
  import PopoverTag from '@/components/pages/PopoverTag.vue';
  import RefDevicelist from "../components/pages/RefDevicelist";
  import * as echarts from 'echarts';
  import DeviceApp from "../components/pages/DeviceApp";
  import DeviceappForm from "../components/pages/DeviceappForm";
  import DevicesoftForm from "../components/pages/DevicesoftForm";
  import LinkForm from "../components/pages/LinkForm";

  const option = {
    legend: [{
      //selectedMode: 'single'
    }],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',

    series: [
      {
        type: 'graph',
        //legendHoverLink: false, //启用图例hover联动高亮
        layout: 'force', //采用力引导布局
        data: [],
        links: [],
        categories: [
          {
            "name": "主机"
          },
          {
            "name": "安全设备"
          },
          {
            "name": "网络设备"
          },
          {
            "name": "其他设备"
          },
          {
            "name": "应用"
          },
          {
            "name": "集群"
          },
          {
            "name": "机房机柜"
          }
        ],
        roam: true, //开启缩放和平移
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        labelLayout: {
          hideOverlap: true
        },
        //连接线标签
        edgeLabel: {
          show: false,
        },
        force: {
          repulsion: 800,
          edgeLength: [150, 200],
          layoutAnimation: true,
          friction: 0.2, //这个参数能减缓节点的移动速度。取值范围 0 到 1。
        },
        lineStyle: {
          color: 'source',
          //width: 2,
          curveness: 0.1
        },
        tooltip: {
          formatter: '{a}'  //c:数值
        }
      }
    ]
  };

  export default {
    name: "DeviceDetail",
    components: {
      hwPopoverSelect: PopoverSelect,
      hwPopoverCheckbox: PopoverCheckbox,
      hwPopoverTag: PopoverTag,
      hwRefDevicelist: RefDevicelist,
      //hwRefsafeForm: RefsafeForm,
      hwDeviceApp: DeviceApp,
      hwDeviceappForm: DeviceappForm,
      hwDevicesoftForm: DevicesoftForm,
      hwLinkForm: LinkForm,
    },
    data() {
      return {
        id: parseInt(this.$route.query.id),
        loading: false,
        activeName: 'map',
        devInfo: {
          safe_options: {},
          safe_account_name: [],
          host_vir: '',
          host_defens: '',
          host_patch: '',
          host_log: '',
          host_vir_name: '',
          safe_firewall: {},
          safe_ports: '',
          ref_info: {
            firewall: {},
            vpn: {},
            log: {},
            fortress: {},
            inspection: {},
            gateway: {},
            access: {},
            gate: {},
            backup: {},
            ips: {},
            internet: {},
            antivirus: {},
            o_safe: {}
          },
          label: {
            netgate: '',
            ips: ''
          }
        },
        safeFirewall: {},
        safeExchange: {},
        safeVpn: {},
        ports: {}, //端口覆盖信息
        applist: [],
        addPop: false,
        refId: null,
        mode: 'add',
        popTitle: '添加软件',
        //refForm: {},
        showMapPop: false,
        cid: null,
        refCid: null,
        refDevId: null,
        refMapId: null,
        modeDev: 'add',

        addAppPop: false,
        addSoftPop: false,
        softId: null,
        //关联设备弹窗设置
        showLinkPop: false,
        //关联信息
        refInfo: {},
      }
    },
    created() {
      this.loadDetail();
    },
    beforeRouteLeave(to, from, next) {
      this.clearChart();
      next();
    },
    mounted() {
      //this.createChart();
    },
    methods: {
      createChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        myChart.showLoading();
        let dev = [
          {
            id: "dev_" + this.devInfo.id,
            name: this.devInfo.name,
            symbolSize: 50,
            category: 0
          },
          {
            id: "room_" + this.devInfo.room_id,
            name: this.devInfo.roomname + '/' + this.devInfo.cabname,
            symbolSize: 40,
            category: 6
          }
        ];
        dev[0].label = {
          show: true,
          fontWeight: 'bold',
          fontSize: '18px'
        }
        let links = [{
          source: "dev_" + this.devInfo.id,
          target: 'room_' + this.devInfo.room_id
        }];

        this.$get(this.$api.GetDeviceChart, {id: this.$route.query.id}).then((res) => {
          myChart.hideLoading();
          let arr = res.nodes;
          let nodes = [...dev, ...arr];
          option.series[0].data = nodes;
          option.series[0].links = [...links, ...res.links];
          myChart.setOption(option);
        });
      },
      clearChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        //myChart.clear();
        option.series[0].data = [];
        myChart.setOption(option);
        myChart.dispose(); //销毁实例
      },
      deleteApp(id, index) {
        this.$confirm('此操作将删除该设备承载的应用吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteDevSoft, {id: id})
              .then((res) => {
                if (res.code === 0) {
                  this.$message({
                    message: '操作成功！',
                    type: 'success'
                  });
                  this.applist.splice(index, 1);
                } else {
                  this.$message.error(res.message);
                }
              });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      loadDetail() {
        this.$get(this.$api.GetDeviceInfo, {id: this.id, type: 'all'}).then((res) => {
            this.devInfo = res.data;
            this.createChart();
        });
      },
      //切换tab
      switchTab(tab) {
        if (tab.name == 'map') {
          this.createChart();
        } else if (tab.name == 'ports') { //获取端口覆盖信息
          this.$nextTick(() => {
            this.getDevicePorts();
          });
        } else if (tab.name == 'netsafe') { //获取网络安全关联信息
          this.$nextTick(() => {
            this.loadDetail();
          });
        }
      },
      //获取承载应用列表
      getApplist() {
        this.loading = true;
        this.$get(this.$api.GetSoftOfDev, {dev_id: this.id}).then((res) => {
          this.loading = false;
            this.applist = res.data.list;
        });
      },

      //获取端口覆盖信息
      getDevicePorts() {
        this.loading = true;
        this.$get(this.$api.GetDevicePorts, {dev_id: this.id}).then((res) => {
          this.loading = false;
            this.ports = res.data;
        });
      },
      closePop() {
        this.addPop = false;
        this.popTitle = '添加软件';
        this.refId = null;
        this.mode = 'add';
        this.activeName = 'soft';
        this.getApplist();
      },
      addApp() {
        this.addAppPop = true;
        this.popTitle = '添加应用';
        this.refId = null;
      },
      addSoft() {
        this.addSoftPop = true;
        this.popTitle = '添加软件';
        this.refId = null;
      },

      //保存修改后的名称
      emitSaveName(field, value) {
        console.log(field, value)
        switch (field) {
          case 'safe_ports':
            this.devInfo.safe_ports = value;
            break;
          case 'host_vir': //杀毒软件
            this.devInfo.host_vir_name = value;
            console.log(this.devInfo)
            break;
          case 'safe_account': //账号安全
            this.devInfo.safe_account = value;
            break;
          case 'safe_remote': //远程登录方式
            this.devInfo.safe_remote = value;
            break;
          case 'safe_defense': //主动防御
            this.devInfo.safe_defense = value;
            break;
          case 'safe_sys': //主动防御
            this.devInfo.safe_sys = value;
            break;
          case 'host_patch': //漏洞与补丁
            this.devInfo.host_patch = value;
            break;
          case 'host_log': //日志审计
            this.devInfo.host_log = value;
            break;
          default:
            break;
        }
      },
      //添加关联设备
      addRefDev() {
        this.$refs.refdev.showPop();
      },
      //添加关联接口
      addRefInterface() {
        this.$refs.refinterface.showPop();
      },
      //编辑关联设备-安全设备
      editRefDev(refCid, refDevId) {
        this.showMapPop = true;
        this.refCid = refCid;
        this.refDevId = refDevId;
      },
      //设置关联设备
      editLinkDev(refCid, label, refId) {
        this.refInfo = {
          refCid: refCid,
          label: label,
          refId: refId
        };
        this.showLinkPop = true;
      },
      closeLinkPop(val, cid, refId) {
        this.showLinkPop = false;
        console.log(val,cid, refId)
        if (cid === undefined) {
          return false;
        }
        let obj = {
          label: val,
          refId: refId
        };
        switch (cid) {
          case 7: //防火墙
            this.devInfo.ref_info.firewall = obj;
            break;
          case 8: //交换机
            this.devInfo.ref_info.exchanger = obj;
            break;
          case 11: //VPN
            this.devInfo.ref_info.vpn = obj;
            break;
          case 19: //路由器
            this.devInfo.ref_info.router = obj;
            break;
          case 23: //日志审计
            this.devInfo.ref_info.log = obj;
            break;
          case 24: //堡垒机
            this.devInfo.ref_info.fortress = obj;
            break;
          case 25: //安全检测
            this.devInfo.ref_info.inspection = obj;
            break;
          case 26: //准入网关
            this.devInfo.ref_info.gateway = obj;
            break;
          case 27: //安全准入
            this.devInfo.ref_info.access = obj;
            break;
          case 28: //网闸
            this.devInfo.ref_info.gate = obj;
            break;
          case 29: //备份设备
            this.devInfo.ref_info.backup = obj;
            break;
          case 30: //IPS
            this.devInfo.ref_info.ips = obj;
            break;
          case 31: //上网行为管理
            this.devInfo.ref_info.internet = obj;
            break;
          case 32: //防病毒
            this.devInfo.ref_info.antivirus = obj;
            break;
        }
      },
      resetForm() {
        this.$refs['refForm'].reset();
      },
      closeAppPop() {
        this.addAppPop = false;
        this.popTitle = '添加应用';
        this.refId = null;
        this.activeName = 'app';
        this.$refs.refapp.getDevAppList();
        //this.getApplist();
      },
      closeSoftPop() {
        this.addSoftPop = false;
        this.$refs.refapp.getSoftList();
      }
    }
  }
</script>

<style scoped lang="less">
  .row {
    margin-top: 20px;
    padding: 2px 0;

    span {
      color: #888;
    }

    i {
      font-style: normal;
    }
  }

  .tab-wrap {
    position: relative;

    .add-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
    }

    .add-btn2 {
      position: absolute;
      right: 120px;
      top: 0px;
      z-index: 999;
    }
  }

  .tab {
    margin-top: 20px;

    .row {
      margin-top: 6px;
    }

    p {
      //padding: 10px 0;
      span {
        color: #888;
      }
    }
  }

  .rows {
    line-height: 32px;
    padding: 6px 0;
    font-size: 14px;

    .label-txt {
      color: #999;
      font-size: 15px;
      text-align: right;
    }
    i{
      font-style: normal;
      margin-right: 8px;
    }

    .edit-ipt {
      width: 200px;
    }

    .edit-btn {
      cursor: pointer;
      color: #999;
      font-size: 13px;
    }
  }
</style>
